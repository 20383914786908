import React from 'react';
import { useForm } from 'react-hook-form';

export default function InputField({
  name,
  label,
  type = 'text',
  placeholder,
  register,
  errors,
  validationRules,
  className = '',
  onFocus,
  is_required = false,
}) {
  return (
    <div className={`input-field-container mb-3 ${className}`}>
      {label && (
        <label>
          {label}
          {is_required && <span className="required-sign">*</span>}
        </label>
      )}
      <input
        id={name}
        type={type}
        className={`form-control ${errors[name] ? 'on-error' : ''}`}
        placeholder={placeholder}
        style={{ fontSize: '14px' }}
        {...register(name, validationRules)}
        onFocus={onFocus}
      />
      {errors[name] && <p className="error-text">{errors[name]?.message}</p>}
    </div>
  );
}
