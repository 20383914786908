import React, { useEffect } from 'react';

const SelectFieldElement = ({
  name,
  label,
  options,
  register,
  errors,
  validationRules,
  className = '',
  setValue,
  defaultValue = '',
}) => {
  useEffect(() => {
    setValue(name, defaultValue);
  }, [setValue, name, defaultValue]);
  return (
    <div className={`select-field-container mb-3 ${className}`}>
      {label && (
        <label htmlFor={name} className="input-label">
          {label}
        </label>
      )}
      <select
        id={name}
        className={`form-select ${errors[name] ? 'on-error' : ''}`}
        {...register(name, validationRules)}
        style={{ fontSize: '14px' }}
        defaultValue={defaultValue} // Setting default selected value
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && <p className="error-text">{errors[name]?.message}</p>}
    </div>
  );
};

export default SelectFieldElement;
