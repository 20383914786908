//import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatUrl, formatDate, formatDate2 } from '../common';
import { assets, environment } from '../data';

export default function Event({ event, disable }) {
  console.log(event, 'eventevent');
  const navigate = useNavigate();

  //useEffect(() => {});

  return (
    <div className="event">
      <div className="banner-wrapper mb-1">
        <div
          className="banner"
          style={{
            backgroundImage: `url(${
              event?.event_banner
                ? environment.gpcStorage + event.event_banner
                : assets.sample_img
            })`,
          }}
        ></div>
      </div>
      {/* <div className="banner-wrapper mb-1">
        <img
          className="banner"
          src={
            event?.event_banner
              ? environment.gpcStorage + event.event_banner
              : assets.sample_img
          }
          alt="event cover"
          loading="lazy"
        />
      </div> */}

      <h3>{event?.event_name || 'Not Available'}</h3>
      <div className="mb-3">
        <span
          className={`active_label ${
            event?.is_indoor ? 'active_label_d' : 'active_label'
          }`}
        >
          {event?.is_indoor
            ? 'Indoor Musical Concert'
            : 'Outdoor Musical Concert'}
        </span>
      </div>

      <div className="location_display">
        <img src={assets.location} alt="Location" className="location_image" />
        <span className="location_text">{event?.venue || 'Not Available'}</span>
      </div>
      <div className="location_display">
        <img src={assets.clock} alt="Location" className="location_image" />
        <span className="location_text">
          {formatDate2(event?.event_datetime)}
        </span>
      </div>
      <div className="inline-container mt-4">
        <span className="price-text">
          {event?.minimum_ticket_amount || ''}{' '}
          {event?.tickets_currency || 'LKR'}{' '}
          <span className="upward">Upwards</span>
        </span>
        <button
          className={`buy-btn ${
            disable ? 'buy-btn-disable' : 'primary-btn buy-btn'
          }`}
          disabled={disable}
          onClick={() => navigate(`/${event.event_details}`)}
        >
          Buy Tickets
        </button>
      </div>
    </div>
  );
}
