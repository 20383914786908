import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import toast from 'react-hot-toast';

import LeftOffCanvas from '../layouts/LeftOffCanvas';
import { formatDate } from '../common';
import { environment, assets } from '../data';
import { useNavigate } from 'react-router-dom';
import InputField from '../components/InputField';
import SelectFieldElement from '../components/SelectFieldElement';
import OnsiteCheckoutPage from '../components/OnsiteCheckoutPage';

const preventZoom = (disable) => {
  const metaViewport = document.querySelector('meta[name="viewport"]');
  if (metaViewport) {
    if (disable) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      );
    } else {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1.0',
      );
    }
  }
};

const PaymentFormPage = () => {
  const location = useLocation();
  const {
    isOpen,
    customFields,
    eventDetails,
    event_id,
    tickets,
    coupenCode,
    total,
  } = location.state || {};
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [additionalFields, setAdditionalFields] = useState();
  const [jsonData, setJsonData] = useState('');
  const [formData, setFormData] = useState({});
  const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);
  const [isSubcribeNews, setIsSubcribeNews] = useState(true);
  const [subtotal, setSubtotal] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  //   useEffect(() => {
  //     const handleSuccess = (event) => {
  //       console.log('Payment successful:', event.detail);
  //       alert(
  //         'Payment successful! Transaction ID: ' + event.detail.transaction_id,
  //       );
  //     };
  //     const handleFailure = (event) => {
  //       toast.error('Payment failed!');
  //       console.log('Payment failed:', event.detail);
  //       alert('Payment failed! Transaction ID: ' + event.detail.transaction_id);
  //     };
  //     window.addEventListener('onePaySuccess', handleSuccess);
  //     window.addEventListener('onePayFail', handleFailure);
  //     // Cleanup on unmount
  //     return () => {
  //       window.removeEventListener('onePaySuccess', handleSuccess);
  //       window.removeEventListener('onePayFail', handleFailure);
  //     };
  //   }, []);
  // Dynamically load OnePay script on mount
  //   useEffect(() => {
  //     const script = document.createElement('script');
  //     script.src = '../components/script2.js';
  //     script.async = true;
  //     document.body.appendChild(script);
  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }, []);
  useEffect(() => {
    // Clean up in case of unmounting
    console.log(tickets, 'tickets');
    const subtotalAmount = tickets.reduce(
      (acc, ticket) => acc + Number(ticket.ticket_amount) * ticket.count,
      0,
    );

    setSubtotal(subtotalAmount);
    setTotalAmount(subtotalAmount + subtotalAmount * 0.01);

    return () => preventZoom(false);
  }, []);
  const handleTermsAndConditionsCheckboxChange = () => {
    setIsTermsAndConditionsChecked(!isTermsAndConditionsChecked);
  };

  const handleSubcribeNewsCheckboxChange = () => {
    setIsSubcribeNews(!isSubcribeNews);
  };
  const handleCustomInputChange = (fieldName, event) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };
  // const handleCustomInputChange = (field_name, event) => {
  //   // const values = [...jsonData];
  //   // values[index] = event.target.value;
  //   // setJsonData(values);
  //   let data = `${field_name} : ${event.target.value},`;
  //   setJsonData(jsonData + data);
  // };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fname: '',
      lname: '',
      email: '',
      phone: '',
      gender: 'M',
      //dob: '',
      verificationMethod: 'DEFAULT',
      verificationId: '',
      //address: '',
    },
    mode: 'onChange',
  });
  function extractCustomError(response) {
    const errorMessages = [];

    const errorString = response.error;
    const fixedErrorString = errorString
      .replace(
        /ErrorDetail\(string='(.*?)', code='(.*?)'\)/g,
        '{"string": "$1", "code": "$2"}',
      )
      .replace(/'/g, '"');

    let errorObj;
    try {
      errorObj = JSON.parse(fixedErrorString);
    } catch (e) {
      return errorMessages;
    }
    for (const key in errorObj) {
      if (Array.isArray(errorObj[key]) && errorObj[key][0]?.string) {
        errorMessages.push(errorObj[key][0].string);
      }
    }
    return errorMessages[0];
  }

  function onSubmit(data) {
    if (!isTermsAndConditionsChecked) {
      toast.error('You have to confirm terms and conditions of attendance');
      return;
    }

    if (isLoading || errors === null) {
      return;
    }

    let ts = [];

    tickets.forEach((t) => {
      let t1 = {
        ticket_id: t.id,
        count: t.count,
      };
      ts.push(t1);
    });

    // let emptyJson = {};
    // customFields.forEach((cf) => {
    //   emptyJson[cf.id] = getValues(`FieldNo${cf.id}`);
    //   return null;
    // });

    let body = {
      event_id: event_id,
      customer_first_name: data.fname,
      customer_last_name: data.lname,
      customer_email: data.email,
      customer_phone_no: data.phone,
      customer_address: 'Address',
      gender: data.gender,
      dob: '12/12/2024',
      verification_method: data.verificationMethod,
      verification_id: data.verificationId,
      tickets: ts,
      coupon_code: coupenCode,
      is_subscribe: isSubcribeNews,
      //additional_fields: jsonData,
      additional_fields: JSON.stringify(formData),
    };

    setIsLoading(true);

    axios
      .post(
        `${environment.BASE_URL2}/oneticket/user/event/transaction-session/create/`,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        const data = res?.data;

        if (data?.status === 100) {
          console.log(data.data, 'data.data');
          if (data.data.is_pending_payment) {
            setIsLoading(false);
            if (window.openPaymentIframe) {
              // Call the global function

              window.openPaymentIframe(
                data.data?.payment_url,
                data.data?.onepay_transaction_id,
              );
            } else {
              console.error(
                "Error: 'window.openPaymentIframe' is not defined!",
              );
            }
          } else {
            setIsLoading(false);
            setJsonData('');
            navigate('/');
            toast.success('Success! Your ticket purchasing successfully', {
              id: 'buyTickets',
            });
          }
        } else if (data?.status === 101) {
          toast(data?.message, {
            style: { backgroundColor: '#fff', color: '#f38f03' }, // custom info styling
          });
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        const customErrors = extractCustomError(err?.response?.data);
        if (customErrors) {
          toast.error(customErrors, { id: 'buyTickets' });
        } else {
          toast.error('Oh! Something went wrong!', { id: 'buyTickets' });
        }
      })
      .finally(() => {
        setJsonData('');
      });
  }

  function getTicketCount() {
    return tickets.reduce((t, num) => t + num.count, 0);
  }

  function checkVMethod() {
    const vMethod = getValues('verificationMethod');

    if (vMethod === 'DEFAULT') {
      return 'Please select a verification method';
    } else {
      return true;
    }
  }

  // check if there are emojis in the address
  function checkAddress() {
    const address = getValues('address');
    const pattern = /\p{Extended_Pictographic}/u;

    if (pattern.test(address)) {
      return "Can't use emojis";
    } else {
      return true;
    }
  }
  const handleFocus = () => preventZoom(true);
  return (
    <div className="container">
      <div className="head-div mb-5">
        <div className="d-flex justify-content-start align-items-start gap-5">
          <div className="event-img d-flex justify-content-center">
            <img
              src={
                eventDetails?.event_banner
                  ? environment.gpcStorage + eventDetails.event_banner
                  : assets.sample_img
              }
              alt="event cover"
              loading="lazy"
              width="100%"
              height="100%"
            />
          </div>
          <div className="w100 h100 d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-start align-items-center">
              <div>
                <div className="event-title pt-4">
                  {eventDetails?.event_name}
                </div>
                <div className="d-flex align-items-center justify-content-start w100">
                  <span className="tickets-count">
                    {formatDate(eventDetails?.event_datetime)}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-2 w100">
              <span className="total-tickets">
                {getTicketCount() || 0}{' '}
                Tickets&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              </span>
              <span className="total-price">
                Total : {total.toFixed(2)} {eventDetails?.tickets_currency}
              </span>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-center flex-column  flex-sm-row align-items-start w100">
          <div className="d-flex flex-column align-items-start input-div-set">
            <div className="sub-title">Billing Details</div>
            <InputField
              name="fname"
              placeholder="Write your first name"
              register={register}
              errors={errors}
              validationRules={{
                required: {
                  value: true,
                  message: 'Please write your first name',
                },
              }}
              className="input-div"
              onFocus={() => console.log('Input focused')}
            />
            <InputField
              name="lname"
              placeholder="Write your last name"
              register={register}
              errors={errors}
              validationRules={{
                required: {
                  value: true,
                  message: 'Please write your last name',
                },
              }}
              className="input-div"
              onFocus={() => console.log('Input focused')}
            />
            <InputField
              name="email"
              placeholder="Write your email address"
              register={register}
              errors={errors}
              validationRules={{
                required: {
                  value: true,
                  message: 'Please write your email',
                },
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Please enter a valid email address',
                },
              }}
              className="input-div"
              onFocus={() => console.log('Input focused')}
            />
            <div className="d-flex justify-content-between align-items-center w100">
              <SelectFieldElement
                name="countryCode"
                register={register}
                errors={errors}
                validationRules={{
                  required: {
                    value: true,
                    message: 'Please select a verification method',
                  },
                }}
                options={[{ value: '+94', label: '+94' }]}
                defaultValue="+94"
                setValue={setValue}
                className="select-div"
              />
              <InputField
                name="phone"
                type="tel"
                placeholder="Write your phone number"
                register={register}
                errors={errors}
                validationRules={{
                  required: {
                    value: true,
                    message: 'Please write your phone number',
                  },
                  pattern: {
                    value: /^\+?[0-9]{8}[0-9]*$/,
                    message: 'Invalid phone number',
                  },
                }}
                className="select-input-div"
                onFocus={() => console.log('Input focused')}
              />
            </div>
            <div className="d-flex justify-content-between align-items-center w100">
              <SelectFieldElement
                name="verificationMethod"
                register={register}
                errors={errors}
                validationRules={{
                  required: {
                    value: true,
                    message: 'Please select a verification method',
                  },
                }}
                options={[
                  { value: 'NIC', label: 'NIC' },
                  { value: 'PASSPORT', label: 'Passport' },
                  { value: 'DRIVING_LICENCE', label: 'Driving Licence' },
                ]}
                defaultValue="NIC"
                setValue={setValue}
                className="select-div"
              />
              <InputField
                name="verificationId"
                placeholder="NIC / Passport / Driving Licence"
                register={register}
                errors={errors}
                validationRules={{
                  required: {
                    value: true,
                    message: 'Please enter your verification ID',
                  },
                }}
                className="select-input-div"
                onFocus={() => console.log('Input focused')}
              />
            </div>
            {customFields && Object.keys(customFields).length > 0 && (
              <div className="sub-title">Additional fields</div>
            )}

            {customFields.map((field) => (
              <div key={field.id} className="w100">
                <InputField
                  name={field.field_name}
                  label={field.field_name}
                  type="text"
                  placeholder="Write here"
                  register={register}
                  errors={errors}
                  validationRules={{
                    required: {
                      value: field.is_required,
                      message: 'This field can not be empty ',
                    },
                    pattern: { value: field.field_regex, message: '' },
                  }}
                  className="select-input-div"
                  onFocus={() => console.log('Input focused')}
                />
              </div>
            ))}
          </div>
          <div className="d-flex flex-column align-items-start details-div">
            <div className="sub-title">Booking Summary</div>
            {tickets.map((ticket, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center w100 mb-2"
              >
                <div className="ticket-name">
                  {ticket?.count} x {ticket?.ticket_name}
                </div>
                <div className="ticket-price">
                  {ticket?.count * ticket?.ticket_amount}.00 LKR
                </div>
              </div>
            ))}

            {/* <div className="d-flex justify-content-between align-items-center w100 mb-2">
              <div className="ticket-name">Sub Total</div>
              <div className="ticket-price">{subtotal}.00 LKR</div>
            </div> */}
            {/* <div className="d-flex justify-content-between align-items-center w100 mb-4 pb-4 border-bottom">
              <div className="ticket-name">Convenience Fee</div>
              <div className="ticket-price">{subtotal * 0.01}.00 LKR</div>
            </div> */}
            <div className="d-flex justify-content-between align-items-center w100 mb-2 pt-2 border-top">
              <div className="ticket-name">Total</div>
              <div className="total-price">{subtotal}.00 LKR</div>
            </div>
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div className="col-12 mb-1 mt-3 d-flex align-items-start">
                <input
                  type="checkbox"
                  className={`form-check-input me-3`}
                  style={{ cursor: 'pointer' }}
                  id="acceptConditions"
                  onChange={handleTermsAndConditionsCheckboxChange}
                  checked={isTermsAndConditionsChecked}
                />
                <label htmlFor="acceptConditions" className="ticket-name">
                  I have read the{' '}
                  <span style={{ color: '#229064' }}>Terms and Conditions</span>{' '}
                  of attendance.
                </label>
              </div>
            </div>
            <div className="d-flex flex-column align-items-start">
              <div className="col-12 mb-3 d-flex align-items-start">
                <input
                  type="checkbox"
                  className={`form-check-input me-3`}
                  style={{ cursor: 'pointer' }}
                  id="acceptConditions"
                  onChange={handleSubcribeNewsCheckboxChange}
                  checked={isSubcribeNews}
                />
                <label htmlFor="acceptConditions" className="ticket-name">
                  Subscribe for newsletters.
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center w100 mb-2 gap-2">
              <div className="back-btn" onClick={() => navigate(-1)}>
                <img src={assets.back_btn} width={27} />
              </div>
              <button
                type="submit"
                className="proceed-btn"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  'Proceed to Pay'
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center w100 mt-5">
        <img
          src={assets.onepay_logo}
          alt="payment options"
          loading="lazy"
          className="onepay-logo"
        />
        <img
          src={assets.payform_img}
          alt="payment options"
          loading="lazy"
          style={{ maxWidth: '874px', width: '100%', height: 'auto' }}
        />
      </div>
      {/* Pass data to PayForm */}
    </div>
  );
};

export default PaymentFormPage;
